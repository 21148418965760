/**
 * Products
 * Products
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = System, 1 = Manufacturer, 2 = Public
 */
export enum ProductAuthLevel {
    System = 0,
    Manufacturer = 1,
    Public = 2
}

