/**
 * Products
 * Products
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Unknown, 1 = Draft, 2 = Pending, 3 = Active, 4 = Obsolete, 10 = Archived
 */
export enum ProductConfigurationVersionStatus {
    Unknown = 'unknown',
    Draft = 'draft',
    Pending = 'pending',
    Active = 'active',
    Obsolete = 'obsolete',
    Archived = 'archived'
}

