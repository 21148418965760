/**
 * Products
 * Products
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Set, 1 = Clear, 2 = Toggle, 3 = Preserve
 */
export enum PublishLockValue {
    Set = 0,
    Clear = 1,
    Toggle = 2,
    Preserve = 3
}

