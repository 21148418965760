/**
 * Products
 * Products
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Unknown, 1 = Ready, 10 = Creating, 20 = Updating, 30 = Removing, -30 = FailedToRemove, -20 = FailedToUpdate, -10 = FailedToCreate
 */
export enum ProductProgrammingStatus {
    Unknown = 0,
    Ready = 1,
    Creating = 10,
    Updating = 20,
    Removing = 30,
    FailedToRemove = -30,
    FailedToUpdate = -20,
    FailedToCreate = -10
}

