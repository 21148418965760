/**
 * Products
 * Products
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Offline, 1 = PartialOnline, 2 = Online, 3 = Error
 */
export enum OnlineStatus {
    Offline = 0,
    PartialOnline = 1,
    Online = 2,
    Error = 3
}

